// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container{
    flex: 66.0633 1;
    display: flex;
}

.watchlist-trade-container{
    height: 100%;
}

.trading-page-container{
    display: flex;
    max-height: -webkit-fill-available;
    height: 100%;
    padding: 10px;
}

.tradingview-container{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}

.tradingview-widget-container iframe{
    border: 1px solid #363a45;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/trading/index.css"],"names":[],"mappings":"AAAA;IACI,eAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kCAAkC;IAClC,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,6BAA6B;IAC7B,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".chart-container{\n    flex: 66.0633 1 0px;\n    display: flex;\n}\n\n.watchlist-trade-container{\n    height: 100%;\n}\n\n.trading-page-container{\n    display: flex;\n    max-height: -webkit-fill-available;\n    height: 100%;\n    padding: 10px;\n}\n\n.tradingview-container{\n    width: -webkit-fill-available;\n    display: flex;\n    flex-direction: column;\n}\n\n.tradingview-widget-container iframe{\n    border: 1px solid #363a45;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
