import React, { useEffect, useState } from 'react';
import TradingViewChart from '../../components/TradingViewChart';
import WatchList from '../../components/WatchList';
import './index.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AccountManagement from './account/AccountManagement';
import { useSelector } from 'react-redux';
import Logout from '../../components/Auth/Logout';
import { fetchAPIs, fetchSymbols, fetchTradingDatas } from '../../utils/api';
import { CircularProgress } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import WatchListItem from '../../components/WatchListItem';
import StatusBar from './account/StatusBar';
import axiosInstance from '../../utils/axios';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: 0,
    color: '#89898b',
    fontSize: '10.5px'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0,
    color: '#89898b',
    fontSize: 10.5
  }
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#101013',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: '10px'
}));

const Trading = () => {
  const [isAuth, setIsAuth] = useState(true);
  const [selectedSymbol, setSelectedSymbol] = useState('EURUSD');
  const [symbols, setSymbols] = useState([]);
  const [bid, setBid] = useState([0, 0, 0, 0, 0, 0]);
  const [ask, setAsk] = useState([0, 0, 0, 0, 0, 0]);
  const [apis, setAPIs] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = React.useState(10000);
  const [amount, setAmount] = React.useState('0.01');
  const [stopLoss, setStopLoss] = React.useState(0);
  const [takeProfit, setTakeProfit] = React.useState(0);
  const [marginUsed, setMarginUsed] = React.useState(0);
  const [marginAvailable, setMarginAvailable] = React.useState(0);
  const [openPositionsData, setOpenPositionsData] = React.useState([]);

  const user = useSelector((state) => state.auth.user);

  const [activeGroup, setActiveGroup] = React.useState(null);
  const [menuVisible, setMenuVisible] = React.useState(false);
  const groupedSymbols = symbols.reduce((acc, value) => {
    const group = acc.find((g) => g.assetName === value.assetName);
    if (group) {
      group.symbols.push(value);
    } else {
      acc.push({ assetName: value.assetName, symbols: [value] });
    }
    return acc;
  }, []);
  const handleMouseEnter = () => {
    setMenuVisible(true);
  };

  const handleMouseLeave = () => {
    setMenuVisible(false);
    setActiveGroup(null); // Reset active group on leave
  };
  const handleNaN = (value) => {
    return isNaN(value) ? 0 : value;
  };
  const handleOption = (option) => {
    const data = {
      amount: amount,
      symbol: selectedSymbol,
      stopLoss: stopLoss,
      takeProfit: takeProfit,
      option: option
    };
    console.log('data', data);
    axiosInstance
      .post('/createPosition', data)
      .then((res) => {
        if (res.data.state) {
          if (res.data.state != 'Your balance is not enough') {
            setIsAuth(false);
            localStorage.removeItem('tradeToken');
            window.location.reload();
          }
          return;
        }
        // console.log("data : ", res.data);
        const { positions, balance, margin } = res.data;
        console.log('Hello res', res);
        setOpenPositionsData(positions);
        setBalance(handleNaN(balance));
        setMarginUsed(handleNaN(margin));
        setMarginAvailable(handleNaN(balance) - handleNaN(margin));
      })
      .catch((err) => {
        console.log('Axios Error with ', err);
      });
  };

  useEffect(() => {
    async function fetchData() {
      setSymbols(await fetchSymbols());
      setAPIs(await fetchAPIs);
      const datas = await fetchTradingDatas();
      setAccounts(datas.accounts);
    }
    fetchData();
  }, [balance]);

  const handleAccountChange = (e) => {
    const selectedAccount = accounts.find(
      (account) => account.token === e.target.value
    );
    if (selectedAccount) {
      localStorage.setItem('tradeToken', selectedAccount.token);
    }
    setLoading(true);
    setTimeout(() => setLoading(false), 5000);
  };
  const getBidIndex = (symbol) => {
    const index = symbols.findIndex((sym) => sym.code === symbol);
    return index;
  };
  const calculateProfit = (entryPrice, exitPrice, quantity) => {
    return (exitPrice - entryPrice) * quantity;
  };

  const calculateLoss = (entryPrice, exitPrice, quantity) => {
    return (entryPrice - exitPrice) * quantity;
  };

  const calculateTicks = (entryPrice, exitPrice, tickSize) => {
    const priceChange = exitPrice - entryPrice;
    return priceChange / tickSize;
  };

  return (
    <>
      {loading ? (
        <div className="loading-container">
          <CircularProgress className="circular-progress" color="success" />
        </div>
      ) : (
        <div>
          <div style={{ height: '50px' }}>
            <p className="text-white absolute m-6">Laser Trader</p>
            <Logout />
            <select
              onChange={handleAccountChange}
              className="account-switch"
              defaultValue={localStorage.getItem('tradeToken')}
            >
              {accounts.map((account, index) => (
                <option key={index} value={account.token}>
                  {account.id} - {account.type} ({account.balance})
                </option>
              ))}
            </select>
          </div>
          <div className="ml-16 pt-10">
            <WatchListItem
              fromCurrency={selectedSymbol.slice(0, 3)}
              toCurrency={selectedSymbol.slice(3, 6)}
            />
          </div>

          <div className="trading-page-container">
            <div className="tradingview-container pl-10">
              <div className="chart-container">
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Item sx={{ height: isAuth ? '675px' : '700px' }} p={5}>
                      <TradingViewChart
                        selectedSymbol={selectedSymbol}
                        setSelectedSymbol={setSelectedSymbol}
                      />
                    </Item>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: 'flex', flexDirection: 'column', flex: '' }}
                  >
                    <Item sx={{ height: '80%' }} p={5}>
                      <WatchList
                        height={isAuth ? '500' : '700'}
                        symbols={symbols}
                        bid={bid}
                        ask={ask}
                        setSelectedSymbol={setSelectedSymbol}
                      />

                      <div className="trading-setting space-y-2">
                        {/* Symbol selection dropdown */}
                        <div className="flex space-x-2 w-full justify-between">
                          <div
                            className="dropdown w-1/3"
                            onMouseLeave={handleMouseLeave}
                          >
                            <button
                              className="dropbtn w-full"
                              onMouseEnter={handleMouseEnter}
                              onClick={() => setMenuVisible((prev) => !prev)}
                            >
                              {selectedSymbol === ''
                                ? 'Select Symbol'
                                : selectedSymbol}
                            </button>
                            {menuVisible && (
                              <div
                                className="dropdown-content"
                                onMouseEnter={handleMouseEnter}
                              >
                                {groupedSymbols.map((group) => (
                                  <div
                                    key={group.assetName}
                                    onMouseEnter={() =>
                                      setActiveGroup(group.assetName)
                                    }
                                    onMouseLeave={() => setActiveGroup(null)}
                                    className="dropdown-group"
                                  >
                                    <div
                                      className={`group-label ${
                                        activeGroup === group.assetName
                                          ? 'active'
                                          : ''
                                      }`}
                                    >
                                      {group.assetName}
                                      <span className="arrow">
                                        {activeGroup === group.assetName
                                          ? '->'
                                          : ''}
                                      </span>
                                    </div>
                                    {activeGroup === group.assetName && (
                                      <div className="child-menu">
                                        {group.symbols.map((symbol) => (
                                          <div
                                            key={symbol.code}
                                            onClick={() => {
                                              setSelectedSymbol(symbol.code);
                                              setMenuVisible(false);
                                            }}
                                            className="child-item"
                                          >
                                            {symbol.name}
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          {/* Sell button */}
                          <div className="flex w-2/3 space-x-2">
                            <button
                              onClick={() => handleOption(true)}
                              className="trading-sell px-[33px] bg-[#298f37] rounded-lg text-white"
                              disabled={selectedSymbol === ''}
                            >
                              Sell
                            </button>

                            {/* Amount input */}
                            <input
                              value={amount}
                              className="trading-amount text-center"
                              onChange={(e) => setAmount(e.target.value)}
                              style={{
                                backgroundColor: 'rgb(40, 40, 40)',
                                color: 'white'
                              }}
                            />

                            {/* Buy button */}
                            <button
                              onClick={() => handleOption(false)}
                              className="trading-buy px-8 bg-[#8f2828] rounded-lg text-white"
                              disabled={selectedSymbol === ''}
                            >
                              Buy
                            </button>
                          </div>
                        </div>
                        <div className="flex space-x-2">
                          <button
                            className="rounded-lg"
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: '#171b25',
                              color: 'white',
                              padding: '10px',
                              width: '100%'
                            }}
                          >
                            <p>Stop Loss</p>
                            <input
                              type="checkbox"
                              style={{ width: 'fit-content' }}
                            />
                          </button>

                          {/* Take Profit input */}
                          <button
                            className="rounded-lg"
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: '#171b25',
                              color: 'white',
                              padding: '10px',
                              width: '100%'
                            }}
                          >
                            <p>Take Profit</p>
                            <input
                              type="checkbox"
                              style={{ width: 'fit-content' }}
                            />
                          </button>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '15px',
                            borderRadius: '10px',
                            marginTop: '3px',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <button
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: '#171b25',
                              color: 'grey',
                              padding: '10px',
                              borderRadius: '10px',
                              width: '100%'
                            }}
                          >
                            <p>{bid[getBidIndex(selectedSymbol)]}</p>
                          </button>
                          <p style={{ fontSize: '12px', color: 'white' }}>
                            Price
                          </p>
                          <button
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: '#171b25',
                              color: 'grey',
                              padding: '10px',
                              borderRadius: '10px',
                              width: '100%'
                            }}
                          >
                            <p style={{ fontSize: '12px' }}>
                              {ask[getBidIndex(selectedSymbol)]}
                            </p>
                          </button>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '15px',
                            borderRadius: '10px',
                            marginTop: '3px',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <button
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: '#171b25',
                              color: 'grey',
                              padding: '10px',
                              borderRadius: '10px',
                              width: '100%'
                            }}
                          >
                            <p>
                              {calculateProfit(
                                ask[getBidIndex(selectedSymbol)],
                                bid[getBidIndex(selectedSymbol)],
                                10
                              ).toFixed(4)}
                            </p>
                          </button>
                          <p style={{ fontSize: '12px', color: 'white' }}>
                            P&L
                          </p>
                          <button
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: '#171b25',
                              color: 'grey',
                              padding: '10px',
                              borderRadius: '10px',
                              width: '100%'
                            }}
                          >
                            <p>
                              {calculateLoss(
                                ask[getBidIndex(selectedSymbol)],
                                bid[getBidIndex(selectedSymbol)],
                                10
                              ).toFixed(4)}
                            </p>
                          </button>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '15px',
                            borderRadius: '10px',
                            marginTop: '3px',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <button
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: '#171b25',
                              color: 'grey',
                              padding: '10px',
                              borderRadius: '10px',
                              width: '100%'
                            }}
                          >
                            <p>
                              {(
                                (ask[getBidIndex(selectedSymbol)] -
                                  bid[getBidIndex(selectedSymbol)]) /
                                2
                              ).toFixed(6)}
                            </p>
                          </button>
                          <p style={{ fontSize: '12px', color: 'white' }}>
                            Ticks
                          </p>
                          <button
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: '#171b25',
                              color: 'grey',
                              padding: '10px',
                              borderRadius: '10px',
                              width: '100%'
                            }}
                          >
                            <p>
                              {(
                                (ask[getBidIndex(selectedSymbol)] -
                                  bid[getBidIndex(selectedSymbol)]) /
                                2
                              ).toFixed(6)}
                            </p>
                          </button>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '15px',
                            borderRadius: '10px',
                            marginTop: '3px',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <button
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: '#171b25',
                              color: 'grey',
                              padding: '10px',
                              borderRadius: '10px',
                              width: '100%'
                            }}
                          >
                            <p>{balance}</p>
                          </button>
                          <p style={{ fontSize: '12px', color: 'white' }}>
                            Balance
                          </p>
                          <button
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: '#171b25',
                              color: 'grey',
                              padding: '10px',
                              borderRadius: '10px',
                              width: '100%'
                            }}
                          >
                            <p>{balance}</p>
                          </button>
                        </div>
                      </div>

                      {/* Stop Loss input */}
                    </Item>
                  </Grid>
                </Grid>
              </div>
              <Box p={1}></Box>
              {isAuth && (
                <Box
                  sx={{
                    borderRadius: '10px',
                    marginBottom: '0px',
                    flex: '33.01 1 0px'
                  }}
                  key="account-management"
                >
                  <AccountManagement
                    setIsAuth={setIsAuth}
                    selectedSymbol={selectedSymbol}
                    setSelectedSymbol={setSelectedSymbol}
                    symbols={symbols}
                    bid={bid}
                    setBid={setBid}
                    ask={ask}
                    apis={apis}
                    setAsk={setAsk}
                    balance={balance}
                    setBalance={setBalance}
                    openPositionsData={openPositionsData}
                    marginUsed={marginUsed}
                    marginAvailable={marginAvailable}
                  />
                </Box>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Trading;
