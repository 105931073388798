let url=""
if (process.env.REACT_APP_NODE_ENV !== 'production') {
    module.exports = {
        BackendEndpoint : "http://localhost:8000/api"
    }
}

else {
    module.exports = {
        BackendEndpoint : "http://backend.lasertrader.co/api"
    }
}

