// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/CurrencyDisplay.css */

.currency-display {
    display: flex;
    align-items: center;
    gap: 0.5em;
    border-radius: 5px;
    color: rgb(200,200,200);
}

.currency-code {
    font-size: 10px;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/WatchListItem/index.css"],"names":[],"mappings":"AAAA,4BAA4B;;AAE5B;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":["/* src/CurrencyDisplay.css */\n\n.currency-display {\n    display: flex;\n    align-items: center;\n    gap: 0.5em;\n    border-radius: 5px;\n    color: rgb(200,200,200);\n}\n\n.currency-code {\n    font-size: 10px;\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
